import avtar1 from '../assets/img/testimonials/no-image.png';
import larryGPhoto from '../assets/img/testimonials/larry_g.png';
import michaelLeselrodPhoto from '../assets/img/testimonials/michael_leselrod.png';
import patrickPhoto from '../assets/img/testimonials/patrick.png';

function Testimonials() {
    return (
        <section id="testimonials" className="testimonials">
            <div className="container">
                <div className="section-title">
                    <h2>Testimonials</h2>
                    <p>What people are <strong>saying</strong> about us</p>
                </div>
                <div className="row">
                    <div className="col-lg-6">
                        <div className="testimonial-item">
                            <img src={larryGPhoto} className="testimonial-img" alt="" />
                            <h3>Lawrence Garnier</h3>
                            <h4>Director of Software Development, CIOX Health, NY</h4>
                            <p>
                                <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                                It was a pleasure to work with Reactive Infotech. The team really understood the solutions needed, executed on a high level, and delivered successfully. I would highly recommend Reactive Infotech to anyone needing a smart and capable development team.
                                <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="testimonial-item mt-4 mt-lg-0">
                            <img src={michaelLeselrodPhoto} className="testimonial-img" alt="" />
                            <h3>Michael Leselrod</h3>
                            <h4>Senior Director of Software Engineering, Broadridge, NY</h4>
                            <p>
                                <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                                I worked with Prawesh for several years on many different projects. He delivered on several big full stack projects from start to finish. He is skilled at front end, business logic and database queries.
                                <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="testimonial-item mt-4">
                            <img src={avtar1} className="testimonial-img" alt="" />
                            <h3>Mayer Spitz</h3>
                            <h4>Techonsoft, Brooklyn, NY</h4>
                            <p>
                                <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                                I enjoyed very much working with Prawesh on several projects, I was pleased to utilize his highly qualified experience to design and maintain the application structure.
                                <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="testimonial-item mt-4">
                            <img src={avtar1} className="testimonial-img" alt="" />
                            <h3>Hanno Coetzee</h3>
                            <h4>Cape Town, South Africa</h4>
                            <p>
                                <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                                Prawesh is a pleasure to work with. He understands the technology and can easily get up to speed with the architecture of the application. He manages his time really well and can be left alone to continue on his own.
                                <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="testimonial-item mt-4">
                            <img src={avtar1} className="testimonial-img" alt="" />
                            <h3>Anthony Serdula</h3>
                            <h4>A. Anthony Corp., NY</h4>
                            <p>
                                <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                                Prawesh helped us with some very complex legacy .net code that was a mess. He is very detail oriented and patient. This was not an easy job and he methodically worked his way through our issues and had a very good skill of prioritizing tasks in order to provide maximum benefit. I would highly recommend his work.
                                <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="testimonial-item mt-4">
                            <img src={patrickPhoto} className="testimonial-img" alt="" />
                            <h3>Patrick Mencias-Lewis</h3>
                            <h4>A. Anthony Corp., NY</h4>
                            <p>
                                <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                                Prawesh is a rockstar at taking complicated legacy code and deciphering the desired outcome. His attention to detail is unparalleled when it comes to understanding and applying changes to complex legacy systems.
                                <br /><br />
                                He modernizes and cleans code while delivering the desired outcome. He can explain in detail issues to both developers and business members.
                                <br /><br />
                                I would highly recommend Prawesh for your future projects.
                                <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
export default Testimonials;