import { Fragment } from "react/cjs/react.production.min";
import Breadcrumb from "../components/Breadcrumb";

const Contact = () => {
    return (
        <Fragment>
            <Breadcrumb PageName="Contact Us" />
            <section id="contact" className="contact">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="info-box mb-4">
                                <i className="bx bx-map"></i>
                                <h3>Our Address</h3>
                                <p>Lower Nehru Gram, Dehradun - 248005, Uttarakhand, India</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="info-box  mb-4">
                                <i className="bx bx-envelope"></i>
                                <h3>Email Us</h3>
                                <p>reactiveinfotech@gmail.com</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="info-box  mb-4">
                                <i className="bx bx-phone-call"></i>
                                <h3>Call Us</h3>
                                <p>+91 - 963 471 2639, 821 860 3227</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 ">
                            <iframe title="contact-map" src="https://www.google.com/maps/embed?pb=!1m28!1m12!1m3!1d27557.180045947338!2d78.04239158319932!3d30.30409503615261!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m13!3e6!4m5!1s0x390928ebcd9042c1%3A0x7eaff9c93a19b5f7!2sNehru%20Gram%2C%20Dehradun%2C%20Uttarakhand!3m2!1d30.2883908!2d78.0902682!4m5!1s0x390929c356c888af%3A0x4c3562c032518799!2sDehradun%2C%20Uttarakhand!3m2!1d30.316494499999997!2d78.03219179999999!5e0!3m2!1sen!2sin!4v1637138808734!5m2!1sen!2sin" width="800" height="600" style={{ border: '0', width: '100%', height: '384px' }} loading="lazy"></iframe>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment >
    );
}
export default Contact;