
const Services = () => {
    return (
        <section id="featured" className="featured">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4">
                        <div className="icon-box">
                            <i className="bi bi-cloud"></i>
                            <h3>Web, Mobile &amp; Cloud Apps</h3>
                            <p>We develop Web, Mobile &amp; Cloud applications for any business.</p>
                        </div>
                    </div>
                    <div className="col-lg-4 mt-4 mt-lg-0">
                        <div className="icon-box">
                            <i className="bi bi-bar-chart"></i>
                            <h3>ORM</h3>
                            <p>We integrate ORM such as Entity Framework, Entity Framework Core etc.</p>
                        </div>
                    </div>
                    <div className="col-lg-4 mt-4 mt-lg-0">
                        <div className="icon-box">
                            <i className="bi bi-binoculars"></i>
                            <h3>Databases</h3>
                            <p>We design &amp; develop high-performance scalable databases using Microsoft SQL Server, Azure etc.</p>
                        </div>
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-lg-4">
                        <div className="icon-box">
                            <i className="bi bi-phone"></i>
                            <h3>Mobile Apps</h3>
                            <p>We design &amp; develop user-friendly mobile apps for iOS, Android and Windows.</p>
                        </div>
                    </div>
                    <div className="col-lg-4 mt-4 mt-lg-0">
                        <div className="icon-box">
                            <i className="bi bi-laptop"></i>
                            <h3>Front End</h3>
                            <p>We enjoy working in all javascript libraries/frameworks including React, Angular, Vue and many more.</p>
                        </div>
                    </div>
                    <div className="col-lg-4 mt-4 mt-lg-0">
                        <div className="icon-box">
                            <i className="bi bi-code"></i>
                            <h3>Back End</h3>
                            <p>We have years of experience in back end development using ASP.NET, ASP.NET CORE, ASP.NET MVC, ASP.NET MVC Core, ASP.NET Web API, Web Forms, Python etc.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
export default Services;