
function Carousel() {
    return (
        <section id="hero">
            <div className="hero-container">
                <div id="heroCarousel" data-bs-interval="5000" className="carousel slide carousel-fade" data-bs-ride="carousel">

                    <ol className="carousel-indicators" id="hero-carousel-indicators">
                        <li data-bs-target="#heroCarousel" data-bs-slide-to="0"></li>
                        <li data-bs-target="#heroCarousel" data-bs-slide-to="1" className="active" aria-current="true"></li>
                        <li data-bs-target="#heroCarousel" data-bs-slide-to="2"></li>
                    </ol>

                    <div className="carousel-inner" role="listbox">
                        <div className="carousel-item active" style={{ backgroundImage: "url('/assets/img/slide/slide-1.jpg')" }}>
                            <div className="carousel-container">
                                <div className="carousel-content">
                                    <h2 className="animate__animated animate__fadeInDown">Custom Software <span>Solutions</span></h2>
                                    <p className="animate__animated animate__fadeInUp">
                                        We are a startup specializing in web and mobile development for any sector, any business.
                                        We assure quality work, punctuality, all deliverable on time without fail,
                                        and are aware of the best coding practices and employ in projects to make them more successful.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="carousel-item" style={{ backgroundImage: "url('/assets/img/slide/slide-2.jpg')" }}>
                            <div className="carousel-container">
                                <div className="carousel-content">
                                    <h2 className="animate__animated fanimate__adeInDown">Creative &amp; <span>Professional</span></h2>
                                    <p className="animate__animated animate__fadeInUp">
                                        We offer customer-oriented software services, deliver in the shortest amount of time and
                                        at the lowest possible cost. We focus on managing and growing your business,
                                        monitor your problems and take decisions to improve them and avoid risks.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="carousel-item" style={{ backgroundImage: "url('/assets/img/slide/slide-3.jpg')" }}>
                            <div className="carousel-container">
                                <div className="carousel-content">
                                    <h2 className="animate__animated animate__fadeInDown">
                                        <span>Responsive</span> and <span>cross broswer</span> compatibility
                                    </h2>
                                    <p className="animate__animated animate__fadeInUp">
                                        The layout gets changed according to the user's device. It creates dynamic changes to the
                                        appearance of a website, depending on the screen size and orientation of the device being used
                                        to view it, whether it is a mobile phone or a tablet or a desktop computer.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <a className="carousel-control-prev" href="#heroCarousel" role="button" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon bi bi-chevron-left" aria-hidden="true"></span>
                    </a>

                    <a className="carousel-control-next" href="#heroCarousel" role="button" data-bs-slide="next">
                        <span className="carousel-control-next-icon bi bi-chevron-right" aria-hidden="true"></span>
                    </a>
                </div>
            </div>
        </section>
    );
}
export default Carousel;