import aboutImage from '../assets/img/about.jpg';

const About = () => {
    return (
        <section id="about" className="about">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <img src={aboutImage} className="img-fluid" alt="" />
                    </div>
                    <div className="col-lg-6 pt-4 pt-lg-0 content">
                        <h3>We Listen keenly before we speak, understand requirements, expectatios &amp; deliver timely with quality.</h3>
                        <p className="fst-italic">
                            With opening of economic corridors favourable for entrepreneurs in India and advancement of technology, Reactive Infotech came into being  in 2018 and is one of the fastest growing information technology solution delivery company specializing in web, mobile & custom software solutions development serving satisfied customers world-wide.
                        </p>
                        <ul>
                            <li><i className="bi bi-check-circle"></i> We believe on strong principles of customer satisfaction first with high quality of work as per global industry standards.</li>
                            <li><i className="bi bi-check-circle"></i> We assure quality work, punctuality &amp; all deliverables on time without fail.</li>
                            <li><i className="bi bi-check-circle"></i> We are aware of the best coding practices and adopt them in programming. </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    );
}
export default About;