import { Fragment } from "react/cjs/react.production.min";
import ServiceContent from "../components/Services";
import Breadcrumb from "../components/Breadcrumb";
import Skills from "../components/Skills";

const Services = () => {
    return (
        <Fragment>
            <Breadcrumb PageName="Services" />
            <ServiceContent />
            <Skills />
        </Fragment>
    );
}
export default Services;