import skillsImage from '../assets/img/skills-img.jpg';

const Skills = () => {
    return (
        <section id="skills" className="skills">
            <div className="container">
                <div className="section-title">
                    <h2>Our Skills</h2>
                    <p>Reactive Infotech is based on strong principles of customer satisfaction first with high quality of work as per global industry standards.</p>
                </div>
                <div className="row">
                    <div className="col-lg-6">
                        <img src={skillsImage} className="img-fluid" alt="" />
                    </div>
                    <div className="col-lg-6 pt-4 pt-lg-0 content">
                        <h3>We believe in doing quality work with minimum repetition</h3>
                        <p className="fst-italic">
                            The area of expertise is as follows but not limited to;
                        </p>
                        <div className="skills-content">
                            <div className="progress">
                                <span className="skill">ASP.NET / ASP.NET CORE <i className="val">90%</i></span>
                                <div className="progress-bar-wrap">
                                    <div className="progress-bar" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style={{ width: "90%" }}></div>
                                </div>
                            </div>

                            <div className="progress">
                                <span className="skill">C# <i className="val">90%</i></span>
                                <div className="progress-bar-wrap">
                                    <div className="progress-bar" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style={{ width: "90%" }}></div>
                                </div>
                            </div>

                            <div className="progress">
                                <span className="skill">SQL SERVER <i className="val">90%</i></span>
                                <div className="progress-bar-wrap">
                                    <div className="progress-bar" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style={{ width: "90%" }}></div>
                                </div>
                            </div>

                            <div className="progress">
                                <span className="skill">REACT / REACT NATIVE / ANGULAR <i className="val">75%</i></span>
                                <div className="progress-bar-wrap">
                                    <div className="progress-bar" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style={{ width: "75%" }}></div>
                                </div>
                            </div>

                            <div className="progress">
                                <span className="skill">HTML &amp; CSS <i className="val">100%</i></span>
                                <div className="progress-bar-wrap">
                                    <div className="progress-bar" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style={{ width: "100%" }}></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
export default Skills;