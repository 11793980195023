import { Fragment } from "react/cjs/react.production.min";
import Carousel from "../components/Carousel";
import Testimonials from "../components/Testimonials";
import About from "../components/About";
import Services from "../components/Services";

const Home = () => {
    return (
        <Fragment>
            <Carousel />
            <main id="main">
                <Services />
                <About />
                <Testimonials />
            </main>
        </Fragment>
    );
}
export default Home;