import { Link } from 'react-router-dom';

function Footer() {
    const currentYear = new Date();
    return (
        <footer id="footer">
            <div className="footer-top">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-md-6 footer-links">
                            <h4>Useful Links</h4>
                            <ul>
                                <li><i className="bx bx-chevron-right"></i> <Link to="/">Home</Link></li>
                                <li><i className="bx bx-chevron-right"></i> <Link to="/about">About Us</Link></li>
                                <li><i className="bx bx-chevron-right"></i> <Link to="/services">Services</Link></li>
                                <li><i className="bx bx-chevron-right"></i> <Link to="/portfolio">Portfolio</Link></li>
                                <li><i className="bx bx-chevron-right"></i> <Link to="/contact">Contact Us</Link></li>
                            </ul>
                        </div>
                        <div className="col-lg-3 col-md-6 footer-links">
                            <h4>Our Services</h4>
                            <ul>
                                <li><i className="bx bx-chevron-right"></i> <Link to="#">Web Design</Link></li>
                                <li><i className="bx bx-chevron-right"></i> <Link to="#">Web / Mobile Development</Link></li>
                                <li><i className="bx bx-chevron-right"></i> <Link to="#">Product Management</Link></li>
                                <li><i className="bx bx-chevron-right"></i> <Link to="#">Marketing</Link></li>
                                <li><i className="bx bx-chevron-right"></i> <Link to="#">Graphic Design</Link></li>
                            </ul>
                        </div>
                        <div className="col-lg-3 col-md-6 footer-contact">
                            <h4>Contact Us</h4>
                            <p>
                                Lower Nehru Gram <br />
                                Dehradun - 248005 <br />
                                Uttarakhand, India <br />
                                <strong>Phone:</strong> +91 - 963 471 2639, 821 860 3227<br />
                                <strong>Email:</strong> reactiveinfotech@gmail.com<br />
                            </p>
                        </div>
                        <div className="col-lg-3 col-md-6 footer-info">
                            <h3>About Reactive Infotech</h3>
                            <p>Reactive Infotech is based on strong principles of customer satisfaction first with high quality of work as per global industry standards.</p>
                            <div className="social-links mt-3">
                                <Link to="/" className="twitter"><i className="bx bxl-twitter"></i></Link>
                                <a href="https://www.facebook.com/Reactive-Infotech-2566224216727227/" className="facebook" target="_blank" rel="noreferrer noopener"><i className="bx bxl-facebook"></i></a>
                                <Link to="#" className="instagram"><i className="bx bxl-instagram"></i></Link>
                                <Link to="#" className="google-plus"><i className="bx bxl-skype"></i></Link>
                                <Link to="#" className="linkedin"><i className="bx bxl-linkedin"></i></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="copyright">
                    &copy; {currentYear.getFullYear()} <strong><span>Reactive Infotech</span></strong>. All Rights Reserved.
                </div>
            </div>
        </footer >
    );
}
export default Footer;