import { Link } from 'react-router-dom';

const Breadcrumb = (props) => {
    return (
        <section id="breadcrumbs" className="breadcrumbs">
            <div className="container">
                <ol>
                    <li><Link to="/">Home</Link></li>
                    <li>{props.PageName}</li>
                </ol>
                <h2>{props.PageName}</h2>
            </div>
        </section>
    );
}
export default Breadcrumb;