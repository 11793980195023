import { Fragment } from 'react/cjs/react.production.min';
import Breadcrumb from '../components/Breadcrumb';
import Testimonials from '../components/Testimonials';
import AboutContent from '../components/About';

function About() {
    return (
        <Fragment>
            <Breadcrumb PageName="About Us" />
            <AboutContent />
            <Testimonials />
        </Fragment>
    );
}
export default About;