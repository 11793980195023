function Header() {
    return (
        <section id="topbar" className="d-flex align-items-center">
            <div className="container d-flex justify-content-center justify-content-md-between">
                <div className="contact-info d-flex align-items-center">
                    <i className="bi bi-envelope d-flex align-items-center"><a href="mailto:contact@example.com">reactiveinfotech@gmail.com</a></i>
                    <i className="bi bi-phone d-flex align-items-center ms-4"><span>+91 - 963 471 2639, 821 860 3227</span></i>
                </div>
                <div className="social-links d-none d-md-flex align-items-center">
                    <a href="https://www.facebook.com/Reactive-Infotech-2566224216727227/" className="facebook" target="_blank" rel="noreferrer noopener"><i className="bi bi-facebook"></i></a>
                </div>
            </div>
        </section >
    );
}
export default Header;