import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

function Navigation() {
    const { pathname } = useLocation();
    return (
        <header id="header" className="d-flex align-items-center">
            <div className="container d-flex justify-content-between align-items-center">
                <div className="logo">
                    <h1><Link to="/"><span style={{ textTransform: "capitalize" }}>Reactive Infotech</span></Link></h1>
                </div>
                <nav id="navbar" className="navbar">
                    <ul>
                        <li><Link to="/" className={pathname.toLowerCase() === "/" ? "active" : ""}><i className="bi bi-house-fill"></i> Home</Link></li>
                        <li><Link to="/about" className={pathname.toLowerCase() === "/about" ? "active" : ""}>About Us</Link></li>
                        <li><Link to="/services" className={pathname.toLowerCase() === "/services" ? "active" : ""}>Services</Link></li>
                        <li><Link to="/portfolio" className={pathname.toLowerCase() === "/portfolio" ? "active" : ""}>Portfolio</Link></li>
                        <li><Link to="/contact" className={pathname.toLowerCase() === "/contact" ? "active" : ""}>Contact Us</Link></li>
                    </ul>
                    <i className="bi bi-list mobile-nav-toggle"></i>
                </nav>
            </div>
        </header>
    );
}
export default Navigation;